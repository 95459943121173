import { Component, Emit, Prop, Watch, Vue } from 'vue-property-decorator';
import { ProductionStepModel } from '@/models/productionStepModel';
import { SupplierModel } from '@/models/supplierModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { CompanyService } from '@/services/companyService';

@Component
export default class NextStep extends Vue {

    @Prop()
    private windowHeight!: number;

    @Prop()
    private scrollPosition!: number;

    @Prop()
    private product!: OrderLineDisplayModel;

    @Prop()
    private showNextStep!: boolean;

    private companyService: CompanyService;

    private supplier: SupplierModel = new SupplierModel();

    private height: number = 0;

    private top: number = 0;

    private stepHtmlElement: HTMLElement | null = null;

    private showInfo: boolean = false;

    public constructor() {
        super();
        this.companyService = new CompanyService();
    }

    private toggleInfo(): void {
        // if(this.showInfo) {
        //     this.showInfo = false;
        // } else {
        //     this.showInfo = true;
        // }
        this.hideAllSteps();
    }

    private redirectGoogle(qstring: string): void {
        let final = "";
        if (qstring.includes('http://') || qstring.includes('https://') || qstring.includes('www.')) {
            final = qstring;
        } else {
            final = "https://www.google.com/search?q="+qstring;
        }
        window.open(final, '_blank');
    }

    @Watch("showNextStep", {deep: true})
    private onChangeNextStep(): void {
        if(this.showNextStep) {
            this.showInfo = true;
        } else {
            this.showInfo = false;
        }
    }

     // Sprint 22, TTD-4210
     @Emit()
     private hideAllSteps(): void { }
}
