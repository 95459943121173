export class HttpError extends Error {
    private statuscodeBackingField: number;
    private messageBackingField: string;

    public constructor(statusCode: number, message: string) {
        super();
        this.statuscodeBackingField = statusCode;
        this.messageBackingField = message;
    }

    public get StatusCode(): number {
        return this.statuscodeBackingField;
    }

    public get Message(): string {
        return this.messageBackingField;
    }
}