import { CompanyClient } from "@/clients/companyClient";
import { ProductClient } from "@/clients/productClient";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { ProductionStepModel } from "@/models/productionStepModel";
import { StepSupplierRequestModel } from "@/models/supplierModel";
import VueRouter from "vue-router";
import { uniq } from "lodash";
import { ProductDataModel } from "@/models/productModel";
import { Guid } from "@/models/guid";

export class ProductService {
    private readonly client: ProductClient;

    private readonly companyClient: CompanyClient;

    public constructor(router: VueRouter) {
        this.client = new ProductClient(router.currentRoute);
        this.companyClient = new CompanyClient();
    }

    public async getProductAsync(orderId: string, orderLineId:string): Promise<OrderLineDisplayModel> {
        const result = await this.client.getProductAsync(orderId, orderLineId);
        return result;
    }

    public async getProductPreviewAsync(orderId: string, orderLineId:string): Promise<OrderLineDisplayModel> {
        const result = await this.client.getProductPreviewAsync(orderId, orderLineId);
        return result;
    }

    public async getProductionStepsAsync(orderId: string, orderLineId:string): Promise<ProductionStepModel[]> {
        const result = await this.client.getProductStepsAsync(orderId, orderLineId);
        let companyIds: string[] = [];
        companyIds.push(...result.map(s => s.toCompanyId));
        companyIds = uniq(companyIds);
        const index = companyIds.findIndex(id => id === Guid.Empty);
        if(index !== -1){
            companyIds.splice(index, 1);
        }
        if(companyIds.length > 0){
            const company: StepSupplierRequestModel = {
                SupplierIds: companyIds
            }
            const supplier = await this.companyClient.getSupplierAsync(company);
            supplier.forEach(s => {
                result.map(r => {
                    if(r.toCompanyId === s.ID){
                        r.supplier = s;
                    }
                })
            })
        }
        return result;
    }

    public async updateProductStepAsync(data: ProductDataModel): Promise<any> {
        const result = await this.client.updateProductStepAsync(data);
        return result;
    }

    public async updateProductStepDetailsAsync(orderId: string, data:any): Promise<any> {
        const result = await this.client.updateProductStepDetailsAsync(orderId, data);
        return result;
    }
}
