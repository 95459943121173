import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ProductionStepModel } from '@/models/productionStepModel';
import { SupplierModel } from '@/models/supplierModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import { AddressModel } from '@/models/addressModel';

@Component
export default class StepDetails extends Vue {

    @Prop()
    private supplier!: SupplierModel;

    @Prop()
    private step!: ProductionStepModel;

    @Prop()
    private product!: OrderLineDisplayModel;

    private currentPictureIndex: number = 0;

    private get checkAddressUnavailable(): boolean {
        return this.supplier == null || this.supplier.factoryAddresses == null || this.supplier.factoryAddresses.length === 0;
    }

    private get address(): string {
        if (this.checkAddressUnavailable) {
            return "-";
        }

        if(this.supplier.factoryAddresses[0].country) {
            const country = this.$t(`countries.${this.supplier.factoryAddresses[0].country}`);
            if(this.supplier.factoryAddresses[0].city && country) {
                return `${this.supplier.factoryAddresses[0].city}, ${country}`;
            } else if(!this.supplier.factoryAddresses[0].city && country) {
                return `${country}`;
            }
        }
        return this.supplier.factoryAddresses[0].city;
    }

    private get contactLine1(): string {
        if (this.checkAddressUnavailable) {
            return "-";
        }
        return this.supplier.factoryAddresses[0].line1;
    }

    private get contactLine2(): string {
        if (this.checkAddressUnavailable) {
            return "-";
        }
        if (this.supplier.factoryAddresses[0].country) {
            const country = this.$t(`countries.${this.supplier.factoryAddresses[0].country}`);
            if(this.supplier.factoryAddresses[0].city && country) {
                return `${this.supplier.factoryAddresses[0].postal} ${this.supplier.factoryAddresses[0].city}, ${country}`;
            } else if(!this.supplier.factoryAddresses[0].city && !this.supplier.factoryAddresses[0].postal && country) {
                return `${country}`;
            }
        } else if (this.supplier.factoryAddresses[0].postal && this.supplier.factoryAddresses[0].city) {
            return `${this.supplier.factoryAddresses[0].postal} ${this.supplier.factoryAddresses[0].city}`;
        }

        return '';
    }

    private get currentImgSrc(): string {
        if (this.supplier == null || this.supplier.profilePics == null || this.supplier.profilePics.length === 0) {
            return "";
        }
        return this.supplier.profilePics[this.currentPictureIndex].content;
    }

    private get defaultStepImage(): string {
        const defaultImageForStep = require.context('../assets/steps/', false, /\.(jpg|png)$/);
        switch(this.step.displayName) {
            case 'Cotton harvest':
                return defaultImageForStep('./' + 'Cotton_Harvest' + ".jpg");
            case 'Spinning':
                return defaultImageForStep('./' + 'Spinning' + ".png");
            case 'Washing':
                return defaultImageForStep('./' + 'Washing' + ".png");
            case 'Confectioning':
                return defaultImageForStep('./' + 'Confectioning' + ".png");
            case 'Transport':
                return defaultImageForStep('./' + 'Transport' + ".png");
            case 'Knitting':
                return defaultImageForStep('./' + 'Knitting' + ".jpg");
            case 'Weaving':
                return defaultImageForStep('./' + 'Weaving' + ".png");
            case 'Dyeing':
                return defaultImageForStep('./' + 'Dyeing' + ".png");
            case 'Printing':
                return defaultImageForStep('./' + 'Printing' + ".png");
            case 'Embroidery':
                return defaultImageForStep('./' + 'Embroidery' + ".png");
            case 'Ginning':
                return defaultImageForStep('./' + 'Ginning' + ".png");
            case 'Importing':
                return defaultImageForStep('./' + 'Importing' + ".png");
            case 'Warehousing':
                return defaultImageForStep('./' + 'Warehousing' + ".png");
            case 'Design & development':
                return defaultImageForStep('./' + 'Design_Development' + ".png");
            case 'Man-made material':
                return defaultImageForStep('./' + 'Man_made_material' + ".png");
            case 'Recycled material':
                return defaultImageForStep('./' + 'Recycled_Material' + ".png");
            case 'Accessories':
                return defaultImageForStep('./' + 'Accessories' + ".png");
            case 'Trims':
                return defaultImageForStep('./' + 'Trims' + ".png");
            case 'Retailing':
                return defaultImageForStep('./' + 'Retailing' + ".png");
            case 'Material trading':
                return defaultImageForStep('./' + 'Material_trading' + ".png");
            case 'Natural material':
                return defaultImageForStep('./' + 'Natural_material' + ".png");
            case 'Yarn trading':
                return defaultImageForStep('./' + 'Yarn_trading' + ".png");
            case 'Fabric trading':
                return defaultImageForStep('./' + 'Fabric_trading' + ".png");
            case 'Cutting':
                return defaultImageForStep('./' + 'Cutting' + ".png");
            case 'Sewing':
                return defaultImageForStep('./' + 'Sewing' + ".png");
            case 'Finishing':
                return defaultImageForStep('./' + 'Finishing' + ".jpg");
            case 'Packing':
                return defaultImageForStep('./' + 'Packing' + ".png");
            case 'Labelling':
                return defaultImageForStep('./' + 'Labelling' + ".png");
            case 'Exporting':
                return defaultImageForStep('./' + 'Exporting' + ".png");
        }
        return '';
    }

    private get supplierName(): string {
        if (this.supplier == null) {
            return "-";
        }
        return this.supplier.name;
    }

    private get supplierProfile(): string {
        if (this.supplier == null) {
            return "-";
        }
        return this.supplier.profile;
    }

    private showNextPicture(): void {
        const maxIndex = this.supplier.profilePics!.length - 1;
        if (this.currentPictureIndex === maxIndex) {
            this.currentPictureIndex = 0;
        } else {
            this.currentPictureIndex++;
        }
    }

    private showPreviousPicture(): void {
        const maxIndex = this.supplier.profilePics!.length - 1;
        if (this.currentPictureIndex === 0) {
            this.currentPictureIndex = maxIndex;
        } else {
            this.currentPictureIndex--;
        }
    }

    @Emit()
    private closeDetails(): void {}
}