import { BaseClient } from "./baseClient";
import { SupplyChainApiAuthenticator } from "../authenticators/supplyChainApiAuthenticator";
import { ApplicationConfig } from "../config";
import { OrderLineDisplayModel } from "@/models/orderLineDisplayModel";
import { Route } from "vue-router";
import { ProductionStepModel } from "@/models/productionStepModel";
import { ProductDataModel } from "@/models/productModel";

export class ProductClient extends BaseClient {
    private readonly endPoint: string = "api/product";

    public constructor(currentRoute: Route) {
        super(ApplicationConfig.endpoints.dashboardApi, new SupplyChainApiAuthenticator(), currentRoute);
    }

    public getProductAsync(orderId: string, orderLineId: string): Promise<OrderLineDisplayModel> {
        return this.get(`${this.endPoint}?o=${orderId}&l=${orderLineId}`);
    }

    public getProductPreviewAsync(orderId: string, orderLineId: string): Promise<OrderLineDisplayModel> {
        return this.get(`api/preview/product?o=${orderId}&l=${orderLineId}`);
    }

    public getProductStepsAsync(orderId: string, orderLineId: string): Promise<ProductionStepModel[]> {
        return this.get(`${this.endPoint}/steps?o=${orderId}&l=${orderLineId}`);
    }

    public async updateProductStepDetailsAsync(fromCompanyId: string, data: object): Promise<any> {
        return this.patch(`${this.endPoint}/clientId/readmore/showDetails/${fromCompanyId}`, data);
    }

    public updateProductStepAsync(data: ProductDataModel): Promise<any> {
        return this.patch(`${this.endPoint}/clientId/supplyChainSteps/showDetails`, data);
    }
}