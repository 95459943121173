import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/home/home.vue';
import Preview from '../views/preview/preview.vue';
import ComingSoon from '../views/comingSoon/comingSoon.vue';
import { UserAuthenticator } from '@/authenticators/userAuthenticator';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/coming-soon',
    name: 'ComingSoon',
    component: ComingSoon
  },
  {
    path: '/preview',
    name: 'Preview',
    component: Preview,
    meta:{
      requiresAuth: true
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach(async (to, from, next) =>{
  switch (true) {
    case to.path?.startsWith("/preview"):
      if (to.query.at) {
        sessionStorage.setItem("access_token", to.query.at.toString());
        next();
      } else {
        const service = new UserAuthenticator();
        await service.authenticateUserAsync();
        next();
      }
      break;
    default:
      next();
      break;
  }

  // if (to.meta !== undefined && to.meta.requiresAuth) {
  //   if (to.meta !== undefined && to.fullPath == "/preview") {
  //       if (to.query.at) {
  //         sessionStorage.setItem("access_token", to.query.at.toString());
  //         next();
  //       } else {
  //         const service = new UserAuthenticator();
  //         await service.authenticateUserAsync();
  //         next({ name: 'Preview' });
  //       }
  //   } else {
  //     next();
  //   }
  // }
});

export default router;
