import { CompanyService } from "@/services/companyService";
import { SupplierModel } from "./supplierModel";

export class ProductionStepModel {
    private companyService: CompanyService;

    public constructor() {
        this.companyService = new CompanyService;
        this.supplier = new SupplierModel();
    }

    public displayName!: string;
    public sequence!: number;
    public toCompanyId!: string;
    public compliantAtVerificationDate!: boolean;
    public supplier!: SupplierModel;
    public showDetails!: boolean;
    public isPublished!: boolean;
    public ID!: string;
    public showFactoryAddress!: boolean;
    public showSupplierName!: boolean;
    public showProfile!: boolean;
    public showNewsLetter!: boolean;
    public showDetailsCount!: number;
    public createdAt!: string;
    public createdBy!: string;
    public stepID!: number;
    public stepState!: number;
    public updatedAt!: string;
    public updatedBy!: string;
    public mainChainStepID!: string;
    public parallelSupplyChainNo!: number;
    public supplyChainType!: string;
    public isCollapsed!: boolean;
}

export class ProductionStepSuppliersModel {
    public displayName!: string;
    public stepID!: number;
    public productionStep!: ProductionStepModel[];
    public sequence!: number;
    public isPublished!: boolean;
    public showStep!: boolean;
    public isCollapsed!: boolean;
}

export class ProductionStepStatusModel {
    public ID!: string;
    public showDetails!: boolean;
    public showProfile!: boolean;
    public showFactoryAddress!: boolean;
    public supplyChainType!: string;
    public parallelSupplyChainNo!: number;
    public mainChainStepID!: string;
    public showSupplierName!: boolean;
}