import { SupplyChainApiAuthenticator } from "@/authenticators/supplyChainApiAuthenticator";
import { ApplicationConfig } from "@/config";
import { ClientModel } from "@/models/clientModel";
import { StepSupplierRequestModel, SupplierModel } from "@/models/supplierModel";
import { BaseClient } from "./baseClient";

export class CompanyClient extends BaseClient {
    private readonly endPoint: string = "api/company";

    public constructor() {
        super(ApplicationConfig.endpoints.dashboardApi, new SupplyChainApiAuthenticator());
    }

    public async getClientAsync(id: string): Promise<ClientModel> {
        return await this.get<ClientModel>(`${this.endPoint}/client/${id}`);
    }

    public async getSupplierAsync(companyIds: StepSupplierRequestModel): Promise<SupplierModel[]> {
        return await this.putWithResponse<SupplierModel[]>(`${this.endPoint}/getSupplierDetails`, companyIds);
    }
}