import { GeolocationClient } from '@/clients/geolocationClient';
import { MapboxTokenModel } from '@/models/mapboxTokenModel';

export class GeolocationService {
    private readonly client: GeolocationClient = new GeolocationClient();

    public async getMapboxToken(): Promise<MapboxTokenModel> {
        const result = await this.client.getMapboxToken();
        return result;
    }

    public async getCoordinates(token: string, query: string): Promise<any> {
        const result = await this.client.getCoordinates(token, query);
        return result;
    }
}