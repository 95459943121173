import { Component, Vue } from 'vue-property-decorator';
import Product from '@/components/product.vue';

@Component({
    components: { Product }
})
export default class Home extends Vue {

    private orderId: string = "";

    private orderLineId: string = "";

    public constructor() {
        super();
    }

    private async created(): Promise<void> {
        this.orderId = this.$route.query.o as string;
        this.orderLineId = this.$route.query.l as string;
    }
}