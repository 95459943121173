import { SupplierStepModel } from "@/models/supplierStepModel";

export class StepsHelper{
    public static stepSequence(): SupplierStepModel[] {
        return [
            {
                displayName: 'Design & development',
                stepID: 14,
                sequence: 1,
                showDefault: true,
            },
            {
                displayName: 'Raw material',
                stepID: 15,
                sequence: 2,
                showDefault: true,
            },
            {
                displayName: 'Man-made material',
                stepID: 30,
                sequence: 3,
                showDefault: true,
            },
            {
                displayName: 'Natural material',
                stepID: 21,
                sequence: 4,
                showDefault: true,
            },
            {
                displayName: 'Recycled material',
                stepID: 16,
                sequence: 5,
                showDefault: true,
            },
            {
                displayName: 'Cotton harvest',
                stepID: 1,
                sequence: 6,
                showDefault: true,
            },
            {
                displayName: 'Ginning',
                stepID: 2,
                sequence: 7,
                showDefault: true,
            },
            {
                displayName: 'Material trading',
                stepID: 20,
                sequence: 8,
                showDefault: true,
            },
            {
                displayName: 'Spinning',
                stepID: 3,
                sequence: 9,
                showDefault: true,
            },
            {
                displayName: 'Yarn trading',
                stepID: 22,
                sequence: 10,
                showDefault: true,
            },
            {
                displayName: 'Knitting',
                stepID: 4,
                sequence: 11,
                showDefault: true,
            },
            {
                displayName: 'Weaving',
                stepID: 5,
                sequence: 12,
                showDefault: true,
            },
            {
                displayName: 'Fabric trading',
                stepID: 23,
                sequence: 13,
                showDefault: true,
            },
            {
                displayName: 'Dyeing',
                stepID: 6,
                sequence: 14,
                showDefault: true,
            },
            {
                displayName: 'Tanning',
                stepID: 31,
                sequence: 15,
                showDefault: true,
            },
            {
                displayName: 'Printing',
                stepID: 8,
                sequence: 16,
                showDefault: true,
            },
            {
                displayName: 'Washing',
                stepID: 7,
                sequence: 17,
                showDefault: true,
            },
            {
                displayName: 'Embroidery',
                stepID: 9,
                sequence: 18,
                showDefault: true,
            },
            {
                displayName: 'Accessories',
                stepID: 17,
                sequence: 19,
                showDefault: true,
            },
            {
                displayName: 'Trims',
                stepID: 18,
                sequence: 20,
                showDefault: true,
            },
            {
                displayName: 'Cutting',
                stepID: 24,
                sequence: 21,
                showDefault: true,
            },
            {
                displayName: 'Sewing',
                stepID: 25,
                sequence: 22,
                showDefault: true,
            },
            {
                displayName: 'Confectioning',
                stepID: 10,
                sequence: 23,
                showDefault: true,
            },
            {
                displayName: 'Assembling',
                stepID: 32,
                sequence: 24,
                showDefault: true,
            },
            {
                displayName: 'Finishing',
                stepID: 26,
                sequence: 25,
                showDefault: true,
            },
            {
                displayName: 'Labelling',
                stepID: 28,
                sequence: 26,
                showDefault: true,
            },
            {
                displayName: 'Packing',
                stepID: 27,
                sequence: 27,
                showDefault: true,
            },
            {
                displayName: 'Transport',
                stepID: 11,
                sequence: 28,
                showDefault: true,
            },
            {
                displayName: 'Exporting',
                stepID: 29,
                sequence: 29,
                showDefault: true,
            },
            {
                displayName: 'Importing',
                stepID: 12,
                sequence: 30,
                showDefault: true,
            },
            {
                displayName: 'Warehousing',
                stepID: 13,
                sequence: 31,
                showDefault: true,
            },
            {
                displayName: 'Retailing',
                stepID: 19,
                sequence: 32,
                showDefault: true,
            }
        ]
    }
}