import Vue from 'vue';
import App from './app.vue';
import MsalFactory from './factories/msalFactory';
import router from './router';
import store from './store';
import i18n from './i18n';
import Notifications from 'vue-notification';

// Bootstrap
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

//components
import Modal from './components/modal.vue';

// Styles
import "./styles/main.scss";

Vue.component('modal', Modal);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);

Vue.use(Notifications);

MsalFactory.initialize();

new Vue({
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app');
