import MsalFactory from "@/factories/msalFactory";
import { Vue, Component } from "vue-property-decorator";
import Product from '@/components/product.vue';

@Component({
  components: { Product }
})
export default class Preview extends Vue {

  private orderId: string = "";

  private orderLineId: string = "";

  public constructor() {
      super();
  }

  private async created(): Promise<void> {
      this.orderId = this.$route.query.o as string;
      this.orderLineId = this.$route.query.l as string;
  }

  public logout(): void {
    MsalFactory.logoutAllAccounts(null);
  }
}