import { IAuthenticator } from "@/authenticators/interfaces/IAuthenticator";
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from "@/i18n";
import { HttpError } from "@/models/httpError";
import { Route } from "vue-router";

type CRUDOperation = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export abstract class BaseClient {
    public constructor(
        private resource: string,
        private authenticator?: IAuthenticator,
        private currentRoute?: Route
    ) { }

    public get<T>(query: string): Promise<T> {
        return this.execute<T>(query, "GET");
    }

    public getText(query: string): Promise<string> {
        return this.execute(query, "GET", true);
    }

    public post(query: string, data: any): Promise<Response> {
        return this.execute(query, "POST", data);
    }

    public postWithResponse<T>(query: string, data: any): Promise<T> {
        return this.execute(query, "POST", data);
    }

    public put(query: string, data: any): Promise<Response> {
        return this.execute(query, "PUT", data);
    }

    public putWithResponse<T>(query: string, data: any): Promise<T> {
        return this.execute(query, "PUT", data);
    }

    public patch(query: string, data: any): Promise<Response> {
        return this.execute(query, "PATCH", data);
    }

    protected delete(query: string): Promise<Response> {
        return this.execute(query, "DELETE");
    }

    private async execute<T>(
        query: string,
        method: CRUDOperation,
        data?: any,
        resultAsText: boolean = false
    ): Promise<T> {
        const url = `${this.resource}/${query}`;
        let request: RequestInit = {
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            },
            method
        };

        if (data) {
            request.body = JSON.stringify(data);
        }

        if (this.needsAuthentication()) {
            if (!this.authenticator) {
                throw new Error("No authenticator configured but call needs authentication. Please add an authenticator");
            }

            request = await this.authenticator.authenticateRequestAsync(request);
        }

        const response = await fetch(url, request);
        switch (response.status) {
            case 400:
            case 404:
                window.location.href = "/coming-soon";
                return undefined as unknown as T;
            case 403:
                return undefined as unknown as T;
            case 204:
                return undefined as unknown as T;
            default:
                return await this.getResultFromResponse(resultAsText, response);
        }
    }

    private needsAuthentication(): boolean {
        return this.currentRoute
            && this.currentRoute?.meta
            && this.currentRoute?.meta.requiresAuth;
    }

    private async getResultFromResponse(resultAsText: boolean, response: Response): Promise<any> {
        let result = {
            code: "",
            message: "",// i18n.t("errors.general").toString(),
            parameters: []
        };

        try {
            if (resultAsText) {
                return await response.text();
            }
            result = await response.json();
        }
        catch {

            // need to check why it's throwing error message for empty response
            if (result.message && response.status) {
                throw new HttpError(response.status, result.message);
            } else {
                return result;
            }

        }

        if (!response.ok) {
            // check whether this is a localized error response or a custom error response
            if ("code" in result) {
                // NotificationHelper.createErrorNotification(i18n.t(result.code, result.parameters).toString());
                throw new HttpError(response.status, result.message);
            }
        }
        return result;
    }
}
