import { ApplicationConfig } from '@/config';
import { IAuthenticator } from './interfaces/IAuthenticator';
import { MsalAuthenticator } from './msalAuthenticator';

export class SupplyChainApiAuthenticator extends MsalAuthenticator implements IAuthenticator {
    public async authenticateRequestAsync(request: RequestInit): Promise<RequestInit> {
        let accessToken = sessionStorage.getItem("access_token");
        if (accessToken == null || accessToken == "" || accessToken == undefined) {
            accessToken = await this.acquireTokenAsync(ApplicationConfig.scopes.dashboardApi);
        }
        request.headers = { ...request.headers, Authorization: `Bearer ${accessToken}` };
        return request;
    }
}
