import { AddressModel } from "./addressModel";
import { CompanyPictureModel } from "./companyPictureModel";

export class SupplierModel {
    public ID?: string;
    public companyId?: string;
    public name!: string;
    public profile!: string;
    public factoryAddresses!: AddressModel[];
    public profilePics!: CompanyPictureModel[];
}

export class StepSupplierRequestModel {
    public SupplierIds!: string[];
}

export class SupplierStatusModel {
    public supplier!: SupplierModel;
    public isPublished!: boolean;
    public showDetails!: boolean;
    public showFactoryAddress!: boolean;
    public showProfile!: boolean;
    public showNewsLetter!: boolean;
    public showDetailsCount!: number;
}