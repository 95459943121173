import { SupplyChainApiAuthenticator } from "@/authenticators/supplyChainApiAuthenticator";
import { ApplicationConfig } from "@/config";
import { BaseClient } from "./baseClient";
import { NotificationHelper } from "@/helpers/notificationHelper";
import i18n from "@/i18n";
import { HttpError } from "@/models/httpError";

export class MarketingClient extends BaseClient {
    private readonly endPoint: string = "api/account";
    private readonly client: string = process.env.VUE_APP_Api_Newsletter !== undefined ? process.env.VUE_APP_Api_Newsletter : "";

    public constructor() {
        super(ApplicationConfig.endpoints.newsletterApi);
    }

    public async subscribe(data: object): Promise<any> {
        const url = `${this.client}/${this.endPoint}/newsletter`;

        let request: RequestInit = {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
            method: "POST",
            body: JSON.stringify(data)
        };

        const response = await fetch(url, request);
        switch (response.status) {
            case 403:
                return "Request not found";
            case 204:
                return "Unauthorized";
            default:
                return await this.getResult(response);
        }
    }

    private async getResult(response: Response): Promise<any> {
        let result = {
            code: "",
            message: i18n.t("errors.general").toString(),
            parameters: []
        };

        try {
            result = await response.json();
        }
        catch {
            throw new HttpError(response.status, result.message);
        }

        if (!response.ok) {
            // check whether this is a localized error response or a custom error response
            if ("code" in result) {
                NotificationHelper.createErrorNotification(i18n.t(result.code, result.parameters).toString());
                throw new HttpError(response.status, result.message);
            }
        }
        return result;
    }
}
