import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Mapbox from "mapbox-gl";
import { MglMap, MglMarker, MglGeojsonLayer } from "vue-mapbox";
import { MarkerModel } from '@/models/markerModel';

@Component({
    components: { MglMap, MglMarker, MglGeojsonLayer }
})
export default class MapJourney extends Vue {
    @Prop()
    private mapboxAccessToken!: string;

    @Prop()
    private markers!: MarkerModel[];

    @Prop()
    private currentMarker!: MarkerModel;

    @Prop()
    private showOverview!: boolean;

    @Prop()
    private mapSmaller!: boolean;

    private mapStyle: string = "mapbox://styles/mapbox/light-v10";

    private mapbox: any = null;

    private actions: any = null;

    private resizeAction: any = null;

    private minLat: number = 0;

    private minLong: number = 0;

    private maxLat: number = 0;

    private maxLong: number = 0;

    public constructor() {
        super();
    }

    private created(): void {
        const latitudes = this.markers.map(m => m.coordinates[0]);
        if (latitudes.length === 0) {
            this.minLat = -90;
            this.maxLat = 90;
        } else {
            this.minLat = Math.min(...latitudes);
            this.maxLat = Math.max(...latitudes);
        }

        const longitudes = this.markers.map(m => m.coordinates[1]);
        if (longitudes.length === 0) {
            this.minLat = -90;
            this.minLong = 90;
        } else {
            this.minLong = Math.min(...longitudes);
            this.maxLong = Math.max(...longitudes);
        }
    }

    private mounted(): void {
        this.mapbox = Mapbox;
    }

    private async onMapLoaded(event: any): Promise<void> {
        this.actions = event.component.actions;

        await this.fitBounds();
        await this.actions.setZoom(1);
    }

    private get coordinates(): number[][] {
        return [[this.minLat, this.maxLong], [this.maxLat, this.minLong]];
    }

    private get geoJsonSource(): any {
        return {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': [
                    {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': this.coordinatesList
                        }
                    }
                ]
            }
        };
    }

    // Sprint 22 updated, TTD-4210
    private get geoJsonlayer(): any {
        return {};
    }

    private get coordinatesList(): number[][] {
        const result: number[][] = [];
        this.markers.forEach(marker => {
            result.push(marker.coordinates);
        });

        return result;
    }

    private async fitBounds(): Promise<void> {
        if (this.actions != null) {
            await this.actions.fitBounds(this.coordinates, {
                padding: 20,
                linear: true,
                duration: 0
            });
        }
    }

    @Watch("mapSmaller")
    private resizeMap(): void {
        if (this.$refs.mapComponent != null && (this.$refs.mapComponent as any).map != null) {
            this.$nextTick().then(() => {
                const mapComponent = this.$refs.mapComponent as any;
                mapComponent.map.resize();
            });
        }
    }
    // Sprint 22 updated, TTD-4210
    // @Watch("showOverview")
    // private async showAll(): Promise<void> {
    //     if (this.showOverview) {
    //         await this.fitBounds();
    //     }
    // }

    @Watch("currentMarker", {deep: true})
    private async currentMarkerChanged(): Promise<void> {
        if (this.actions == null) {
            return;
        }
        if (this.showOverview) {
            await this.fitBounds();
        } else {
            await this.actions.jumpTo({
                center: this.currentMarker.coordinates,
                zoom: 12
            });
        }
    }
}