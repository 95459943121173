import { MarketingService } from '@/services/marketingService';
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { ProductionStepModel } from '@/models/productionStepModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import Policy from '@/components/policy.vue';

@Component({
    components: { "policy-popup": Policy }
})
export default class Contact extends Vue {

    @Prop()
    private step!: ProductionStepModel;

    @Prop()
    private product!: OrderLineDisplayModel;

    private email: string = "";

    private showError: boolean = false;

    private subscribed: boolean = false;

    private subscribing: boolean = false;

    private marketingService: MarketingService = new MarketingService();

    private showModalPolicy: boolean = false;

    private brandRetailerImg: string = "";

    private consumerImg: string = "";

    private supplierImg: string = "";

    private userType: string = "";

    private mounted(): void {
        if (this.product.product.showNewsLetter) {
            this.setCheckboxStatus(this.product.product.showNewsLetter);
        }
        const img = require.context('../assets/icons/', false, /\.(svg)$/);
        this.brandRetailerImg = img('./' + 'radiobutton-icon' + ".svg");
        this.consumerImg = img('./' + 'radiobutton-icon' + ".svg");
        this.supplierImg = img('./' + 'radiobutton-icon' + ".svg");
    }

    private setCheckboxStatus(showNL: any): void {
        const checkboxLable = document.getElementById("check-newsletter") as HTMLInputElement;
        if (showNL && !checkboxLable.classList.contains('checked')) {
            checkboxLable.classList.add("checked");
        } else {
            checkboxLable.classList.remove("checked");
        }
    }

    private async subscribe(): Promise<void> {
        try {
            if (this.email.length === 0 || !this.email.includes("@") || !this.email.includes(".")) {
                this.showError = true;
                return;
            }
            const d = new Date();
            const now = d.toISOString();
            const body = {
                "companyType": this.userType,
                "email": this.email,
                "subscribeNewsletterOn": now
            };
            this.subscribing = true;
            await this.marketingService.subscribe(body);
            this.subscribed = true;
        }
        finally {
            this.subscribing = false;
        }
    }

    private checkStatus() {
        const checkboxLable = document.getElementById("check-newsletter") as HTMLInputElement;
        checkboxLable.classList.toggle("checked");
        const checkbox = document.getElementById("checkbox-newsletter") as HTMLInputElement;
        if (checkboxLable.classList.contains('checked')) {
            checkbox.checked = true;
            this.product.product.showNewsLetter = true;
        } else {
            checkbox.checked = false;
            this.product.product.showNewsLetter = false;
        }
    }

    private showPolicy() {
        this.showModalPolicy = true;
    }

    private defaultStepImage(type: string) {
        const img = require.context('../assets/icons/', false, /\.(svg)$/);
        if (type == "brandretailer") {
            this.brandRetailerImg = img('./' + 'radiobutton-selected-icon' + ".svg");
            this.consumerImg = img('./' + 'radiobutton-icon' + ".svg");
            this.supplierImg = img('./' + 'radiobutton-icon' + ".svg");
        }
        if (type == "consumer") {
            this.brandRetailerImg = img('./' + 'radiobutton-icon' + ".svg");
            this.consumerImg = img('./' + 'radiobutton-selected-icon' + ".svg");
            this.supplierImg = img('./' + 'radiobutton-icon' + ".svg");
        }
        if (type == "supplier") {
            this.brandRetailerImg = img('./' + 'radiobutton-icon' + ".svg");
            this.consumerImg = img('./' + 'radiobutton-icon' + ".svg");
            this.supplierImg = img('./' + 'radiobutton-selected-icon' + ".svg");
        }
    }

    private updateType(type: string) {
        this.defaultStepImage(type);
        this.userType = type;
    }

    @Watch("email")
    private emailChanged(): void {
        if (this.email.length > 0 && this.email.includes("@") && this.email.includes(".")) {
            this.showError = false;
        }
    }
}