import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { ProductionStepModel, ProductionStepSuppliersModel } from '@/models/productionStepModel';
import { OrderLineDisplayModel } from '@/models/orderLineDisplayModel';
import Step from '@/components/step.vue';
import NextStep from '@/components/nextStep.vue';
import { SupplierModel } from '@/models/supplierModel';

@Component({
    components: { Step, NextStep }
})
export default class Steps extends Vue {
    @Prop()
    private steps!: ProductionStepSuppliersModel[];

    @Prop()
    private windowHeight!: number;

    @Prop()
    private scrollPosition!: number;

    @Prop()
    private product!: OrderLineDisplayModel;

    private showNextStep: boolean = false;

    private hideOtherStepsHandler(step: ProductionStepSuppliersModel): void {
        this.showNextStep = false;
        this.hideOtherSteps(step);
    }

    private hideAllStepsHandler(): void {
        this.showNextStep = !this.showNextStep;
        this.hideAllSteps();
    }

    // Sprint 22, TTD-4210
    @Emit()
    private activeStep(step: ProductionStepModel): void { }

    // Sprint 22, TTD-4210
    @Emit()
    private removeActiveStep(): void { }

    // Sprint 22, TTD-4210
    @Emit()
    private hideOtherSteps(step: ProductionStepSuppliersModel): void { }

    @Emit()
    private hideAllSteps(): void { }

    @Emit()
    private updatedStepDetails(step: ProductionStepSuppliersModel, product: OrderLineDisplayModel): void { }
}