import { CompanyClient } from "@/clients/companyClient";
import { ClientModel } from "@/models/clientModel";
import { SupplierModel } from "@/models/supplierModel";

export class CompanyService {
    private readonly client: CompanyClient = new CompanyClient();

    public async getClientAsync(clientId: string): Promise<ClientModel> {
        const result = await this.client.getClientAsync(clientId);
        return result;
    }
}
