import i18n from '@/i18n';
import Vue from 'vue';

export class NotificationHelper {
    public static createSucceededNotification(message: string): void {
        this.createNotification(i18n.t('global.notifications.succeeded').toString(), message, 'success');
    }

    public static createWarningNotification(message: string): void {
     //   this.createNotification(i18n.t('global.notifications.warning').toString(), message, 'warning');
    }

    public static createErrorNotification(message: string): void {
     //   this.createNotification(i18n.t('global.notifications.error').toString(), message, 'error');
    }

    private static createNotification(title: string, message: string, type: string): void {
      Vue.notify({
          group: 'appNotifications',
          title: title,
          duration: 10000,
          type: type,
          text: message
      });
  }
}