import * as msal from "@azure/msal-browser";
import { ApplicationConfig, textracerPolicies } from "../config";
import { ServerError } from "@azure/msal-common";
import MsalFactory from '@/factories/msalFactory';
import { RedirectRequest } from "@azure/msal-browser";
import { AppInsightsHelper } from "@/helpers/appInsightsHelper";

export class MsalAuthenticator {
    protected async signInAsync(): Promise<msal.AccountInfo | null> {
        const account = await this.checkCacheForExisingAccountAsync();
        if (account == null) {
            const request = textracerPolicies.authorities.signUpSignIn;
            request.scopes = ApplicationConfig.scopes.dashboardApi;
            MsalFactory.getMsalContext().loginRedirect(request);
            return null;
        } else {
            return account;
        }
    }

    protected async acquireTokenAsync(scopes: string[]): Promise<string | null> {
        const account = this.getLoggedInAccount();
        if (account == null) {
            return null;
        }

        try {
            const silentRequest = {
                account: account,
                scopes: scopes,
                forceRefresh: false
            };

            const result = await MsalFactory.getMsalContext().acquireTokenSilent(silentRequest);
            if (!result.accessToken) {
                throw Error();
            }

            return result.accessToken;
        }
        catch {
            const redirectRequest = {
                account: account,
                scopes: scopes,
                forceRefresh: false
            };
            // todo: figure out how to handle this callback. Workaround = request all desired scopes while loggin in.
            MsalFactory.getMsalContext().acquireTokenRedirect(redirectRequest);
            return null;
        }
    }

    private async checkCacheForExisingAccountAsync(): Promise<msal.AccountInfo | null> {
        try {
            const response = await MsalFactory.getMsalContext().handleRedirectPromise();
            if (response !== null) {
                return response.account;
            } else {
                const loggedInAccount = this.getLoggedInAccount();
                // todo: check token expiration, return null if token is expired. (get exp claim from idToken)
                return loggedInAccount;
            }
        } catch (err) {
            return null;
        }
    }

    private getLoggedInAccount(): msal.AccountInfo | null {
        /**
        * See here for more info on account retrieval:
        * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
        */
        const allAccounts = MsalFactory.getMsalContext().getAllAccounts();
        if (allAccounts.length == 1) {
            return allAccounts[0];
        }
        else if (allAccounts.length > 1) {
            AppInsightsHelper.trackError(
                new Error("Multipe logged in accounts detected. Logging out user"));
            MsalFactory.logoutAllAccounts(null);
        }

        return null;
    }
}